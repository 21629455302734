import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

export function OptionsButton(props) {
    const {onClick} = props;

    return (
        <button id="options-button" onClick={onClick}>
            <FontAwesomeIcon icon={["fas", "cog"]} />
        </button>
    );
}

export class OptionsWindow extends Component {
    constructor(props) {
        super(props);

        const {bits, useByteSpacing} = props;

        this.state = {
            bits: bits,
            useByteSpacing: useByteSpacing,
        }

        this.bitsInput = React.createRef();
        this.useByteSpacingInput = React.createRef();

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeydownSubmit = this.handleKeydownSubmit.bind(this);
        this.handleLabelClick = this.handleLabelClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleKeydownSubmit(event) {
        if (event.key !== 'Enter') {
            return;
        }

        this.handleSubmit();
    }

    handleInputChange(event) {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        if (typeof target.attributes.type !== "undefined" 
            && target.attributes.type.value === "checkbox") {
            value = target.checked;
        }
        this.setState({[name]: value});
    }

    handleSubmit() {
        const {
            onBitsChange, 
            onUseByteSpacingChange, 
            min, max
        } = this.props;

        // validate bits
        const oldBits = this.props.bits;
        let bits = this.state.bits;
        if (bits !== oldBits) {
            bits = Number(bits);
            if (!isNaN(bits) && (min <= bits && bits <= max)) {
                onBitsChange(bits);
            }
        }

        // validate useByteSpacing
        if (this.props.useByteSpacing !== this.state.useByteSpacing) {
            onUseByteSpacingChange(this.state.useByteSpacing);
        }
    }

    handleLabelClick(input) {
        if (typeof input === "undefined") {
            return;
        }

        input.current.focus();
    }

    render() {
        const {visible} = this.props;

        if (!visible) {
            return <></>;
        }

        return (
            <div id="options-window">
                <div className="options-window_input-row">
                    <label htmlFor="bits" 
                        onClick={() => this.handleLabelClick(this.bitsInput)}>
                        number of bits
                    </label>
                    <input name="bits" 
                        onChange={this.handleInputChange}
                        onKeyPress={this.handleKeydownSubmit}
                        type="text" 
                        inputMode="numeric" 
                        placeholder="8"
                        ref={this.bitsInput}
                        value={this.state.bits} />
                </div>
                <div className="options-window_input-row">
                    <label htmlFor="useByteSpacing">byte spacing</label>
                    <Toggle id="useByteSpacing"
                        name="useByteSpacing"
                        onChange={this.handleInputChange}
                        icons={false}
                        ref={this.useByteSpacingInput}
                        checked={this.state.useByteSpacing} />
                </div>
                <div id="options-window_submit-row">
                    <button className="button" 
                        onClick={this.handleSubmit}>
                        apply
                    </button>
                </div>
            </div>
        );
    }
}
