import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import 'crypto';

import {OptionsButton, OptionsWindow} from './components/options';
import './css/index.css';

library.add(fas);

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputGuess:     "",
            bits:           8,
            randomValue:    "00000000",
            inputTimer:     0,   // time in ms current value
            timePerValue:   0.0, // running mean of time (ms) per value
            valueNumber:    0,   // total number of values shown so far

            useByteSpacing: true,
            showOptions: false,
            gameStopped: true,
        };

        this.handleInputGuessChange = this.handleInputGuessChange.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOptionsButtonClick = this.handleOptionsButtonClick.bind(this);
        this.tick = this.tick.bind(this);

        this.handleBitsChange = this.handleBitsChange.bind(this);
        this.handleUseByteSpacingChange = 
            this.handleUseByteSpacingChange.bind(this);
    }

    componentDidMount() {
        this.newRandomNumber();
    }

    handleInputGuessChange(event) {
        const value = event.target.value;
        if (value.length > 10) {
            return;
        }

        this.setState({inputGuess: value});
    }

    handleBitsChange(value) {
        this.setState({bits: value});
        this.stopGame();
    }

    handleUseByteSpacingChange(value) {
        this.setState({useByteSpacing: value});
    }

    handleResetClick(event) {
        this.stopGame();
    }

    /**
     * Check input against answer. If correct:
     *      Update stats: rate;
     *      Zero the timer;
     *      Generate a new question;
     *      Consider game started if it isn't.
     * If incorrect:
     *      Erase the user input.
     */
    handleSubmit(event) {
        event.preventDefault();

        const {randomValue, inputGuess} = this.state;
        if (parseInt(randomValue, 2) === parseInt(inputGuess)) {
            // order is important for calculating input rate
            this.updateInputRate();
            this.resetInputTimer();
            this.newRandomNumber();
            this.setState({gameStopped: false});
        }

        this.setState({inputGuess: ""});
    }

    handleOptionsButtonClick(event) {
        event.preventDefault();
        this.setState((state) => {
            return {showOptions: !state.showOptions};
        });
    }

    /**
     * Reset and stop timer, reset counters, set new value.
     */
    stopGame() {
        this.resetInputTimer(true);
        this.newRandomNumber();
        this.setState({valueNumber: 0, timePerValue: 0.0, gameStopped: true});
    }

    updateInputRate() {
        this.setState((state) => {
            const {valueNumber, timePerValue, inputTimer} = state;
            return {
                timePerValue: runningMean(valueNumber, timePerValue, inputTimer)
            };
        });
    }

    newRandomNumber() {
        this.setState((state) => {
            const maxval = Math.pow(2, state.bits);
            const value = AsBinaryString(Math.floor(Math.random() * maxval), state.bits);
            return {
                randomValue: value, 
                valueNumber: state.valueNumber + 1,
            };
        });
    }

    resetInputTimer(stop = false) {
        clearInterval(this.inputTimerIntervalId);
        this.setState({inputTimer: 0});
        if (stop === false) {
            this.inputTimerIntervalId = setInterval(this.tick, 1000);
        }
    }

    tick() {
        let previousTime = this.state.inputTimer;
        this.setState({inputTimer: previousTime + 1000});
    }

    render() {
        return (
            <>
                <header>
                    <div className="title-box">
                        <span id="title">fluent binary</span>
                    </div>
                    <OptionsButton onClick={this.handleOptionsButtonClick} />
                </header>
                <main> 
                    <OptionsWindow visible={this.state.showOptions} 
                        onBitsChange={this.handleBitsChange}
                        onUseByteSpacingChange={this.handleUseByteSpacingChange}
                        bits={this.state.bits}
                        useByteSpacing={this.state.useByteSpacing}
                        max={32} min={2}
                    />
                    <div className="column">
                        <div id="game-window">
                            <DisplayRate rate={1000/this.state.timePerValue} />
                            <DisplayBinary useByteSpacing={this.state.useByteSpacing}
                                value={this.state.randomValue} />
                            <div className="game-stopped-notice-box">
                                {this.state.gameStopped && ( 
                                    <div className="game-stopped-notice">
                                        <div className="game-stopped-notice_title">
                                            stopped
                                        </div>
                                        <div className="game-stopped-notice_message">
                                            type the first<br/>number to start
                                        </div>
                                    </div>
                                )}
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <InputField 
                                    value={this.state.inputGuess} 
                                    placeholder={(this.state.gameStopped && parseInt(this.state.randomValue, 2)) || ""}
                                    inputName="inputGuess"
                                    onInputChange={this.handleInputGuessChange} />
                                <button type="submit" className="hidden" />
                            </form>
                        </div>
                        <div id="game-controls">
                            <button onClick={this.handleResetClick}>reset</button>
                        </div>
                    </div>
                </main>
                <footer>
                    <span id="maker">made by solhäll, 2021</span>
                </footer>
            </>
        );
    }
}

/*
 * Calculate new mean in a sequence based on previous mean and new values.
 *
 * @param  n - current index number
 * @param  m - previous mean
 * @param  a - latest value in sequence
 *
 * @return current mean
 */
function runningMean(n, m, a) {
    return ((n - 1)*m + a)/n;
}

function byteSpace(str) {
    const byteLength = Math.ceil(str.length/4);
    const rem = str.length - 4*(byteLength - 1);
    let bytes = new Array(byteLength);
    for (let i = 0; 4*i < str.length; i++) {
        let start = str.length - 4*(i + 1);
        let end = str.length - 4*i;
        bytes[i] = str.slice(start, end);
    }

    if (rem > 0) {
        bytes[byteLength - 1] = str.slice(0, rem);
    }

    return bytes.reverse().join(" ");
}

function DisplayBinary(props) {
    const { value, useByteSpacing } = props;

    let displayValue = value;
    if (useByteSpacing) {
        displayValue = byteSpace(value);
    }

    let style = {};
    if (displayValue.length > 16) {
        style = {fontSize: "1rem"};
    } else if (displayValue.length > 8) {
        style = {fontSize: "1.5rem"};
    }

    return (
        <div className="random-binary" style={style}>
            {displayValue}
        </div>
    );
}

function DisplayRate(props) {
    const { rate } = props;

    let displayRate = Number.parseFloat(rate*60).toFixed(1);
    if (!isFinite(displayRate)) { 
        displayRate = 0.0; 
    }

    return (
        <div className="display-rate">
            numbers per minute: {displayRate}
        </div>
    );
}

function AsBinaryString(val, len) {
    let str = (val >>> 0).toString(2);

    while (str.length < len) {
        str = "0" + str;
    }

    return str;
}

function InputField(props) {
    const {value, inputName, onInputChange, placeholder} = props;

    const displayPlaceholder = String(placeholder);
    let size = Math.max(value.length, displayPlaceholder.length);
    let style = {};
    if (size < 5) {
        size = 5;
    } else if (size > 6) {
        style = {fontSize: "2rem"};
    }

    return (
        <div className="input-container">
            <input 
                className="user-guess-input"
                type="text" 
                name={inputName}
                value={value} 
                onChange={onInputChange} 
                size={size}
                inputMode="numeric"
                placeholder={displayPlaceholder}
                style={style}
                maxLength="10"
            />
        </div>
    );
}

ReactDOM.render(<App />, document.getElementById('app'));
